<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="onOpenOrderCounter"
            >

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.name`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>
        
    </v-row>
</template>

<script>
import { mapActions } from 'vuex';
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "OrderCounters",
    columnIndex: {
        'name': 2, 
        'value': 3, 
        'reserve': 4
    },
    props: {
        search: String
    },
    data: () => ({
        loading: true,
        cancellationTokenSorce: null,
        total: 0,            
        entities: [],
        headers: [
            { text: i18n.t("Наименование"), value: 'name' },
            { text: i18n.t("Значение"), value: 'value', width: '10%' },
        ],
        prevTableFilterObject: null,
        paginationPageCount: 0,
    }),
    computed: {
        collection() {
            return this.$store.getters['settings/getCollection'];
        },
        options: {
            get: function() {
                return this.$store.getters['settings/orderCounters/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('settings/orderCounters/SET_TABLE_OPTION', newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        },
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async getData () {
            if (this.$_.isEqual(this.prevTableFilterObject, this.getSerializedFilter()))
                return;
                
            this.prevTableFilterObject = this.getSerializedFilter();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let response = await httpAPI({
                url: `api/settings/list?collection=${this.collection}&filter=${this.getSerializedFilter()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getSerializedFilter() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;

            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "name" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 2;

            return JSON.stringify(
            {
                SearchStr,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            });
        },
        async onOpenOrderCounter(event, data) {
            try
            {
                let counter = await this.$store.dispatch('dialogs/orderCounter/open', data.item);

                this.setOverlayVisible({ visible: true, message: 'Сохранение...' });

                let saveResponse = await httpAPI({
                    url: `api/actions/save?type=Orders.Counter`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ parent: counter, childrens: null }) },
                });

                this.setOverlayVisible({ visible: false });

                if (saveResponse) {
                    await this.getData();
                    this.$notify.success(saveResponse.data.message);
                }

            }
            catch (ex)
            {
                console.log(ex);
            }
        }
    },
    watch: {
        filter: {
            async handler () {
                await this.getData();
            },
            deep: true,
        },
    },
    mounted() {
        this.$eventBus.$on('update-data-table', async () => {
            await this.getData();
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('update-data-table');
    }
}
</script>