<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                class="elevation-1 customization-list-table pagination-list-table sticky-table"
                @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: `Chancellery.Contractor${status}` })"
            >

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.name`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.contacts`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.contacts">
                                {{item.contacts}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:footer.prepend>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>
        
    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "Contragents",
    columnIndex: {
        'name': 2, 
        'contacts': 3, 
        'inn': 4, 
        'address': 5
    },
    props: {
        status: String,
        search: String
    },
    data: () => ({
        loading: true,
        cancellationTokenSorce: null,
        total: 0,            
        entities: [],
        headers: [
            { text: i18n.t("ИИН/БИН"), value: 'inn', width: '150px' },
            { text: i18n.t("Наименование"), value: 'name' },
            { text: i18n.t("Контакты"), value: 'contacts', width: '25%', sortable: false },
        ],
        prevTableFilterObject: null,
        paginationPageCount: 0,
    }),
    computed: {
        options: {
            get: function() {
                return this.$store.getters['settings/contragents/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('settings/contragents/SET_TABLE_OPTION', newValue);
            }
        },
        collection() {
            return this.$store.getters['settings/getCollection'];
        },
        filter() {
            return {
                ...this.options,
                SearchStr: this.search,
                Contractor : this.status
            }
        },
    },
    methods: {
        resetPage() {
            this.$store.commit('settings/contragents/SET_TABLE_OPTION_PAGE', 1);
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;
                
            this.prevTableFilterObject = this.getTableFilterObject();
            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();
            
            let response = await httpAPI({
                url: `api/settings/list?collection=${this.collection}&filter=${JSON.stringify(this.getTableFilterObject())}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, Contractor } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "name" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 2;
            return {
                SearchStr,
                Contractor,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            };
        },
    },
    watch: {
        filter: {
            async handler() {
                await this.getData();
            },
            deep: true,
        },
    }
}
</script>