<template>

    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">
                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    :label="$t('Что_ищем')"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
            v-bind:substatus.sync="status"
        />

        <component 
            :is="collection"
            :status="status"
            :search="search"
            ref="currentComponent"
        />

    </div>

</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import ChancelleryCounters from './lists/ChancelleryCounters.vue'
import EuolCounters from './lists/EuolCounters.vue'
import Nomenclatures from './lists/Nomenclatures.vue'
import Contragents from './lists/Contragents.vue'
import ExecuterGroups from './lists/ExecuterGroups.vue'
import OrderCounters from './lists/OrderCounters.vue'

export default {
    name: "SettingsProxy",
    components: {
        Toolbar,
        FilterPanel,
        'Chancellery.Counter' : ChancelleryCounters,
        'CitizenStatements.EuolCounter': EuolCounters,
        'Chancellery.Nomenclature': Nomenclatures,
        'Chancellery.Contragent': Contragents,
        'Documents.ExecuterGroup': ExecuterGroups,
        'Orders.Counter': OrderCounters
    },
    data: () => ({
        cancellationTokenSorce: null,
        dataSource: null,
        filterDataSource: null
    }),
    computed: {
        collection: {
            get: function() {
                return this.$store.getters['settings/getCollection'];
            },
            set: function(newValue) {
                this.$store.commit('settings/SET_COLLECTION', newValue);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['settings/getStatus'];
            },
            set: function(newValue) {
                if(newValue) {
                    this.$store.commit('settings/PUSH_STATUS', { collection: this.collection, value: newValue } );

                    if (typeof this.$refs.currentComponent?.resetPage === "function") { 
                        this.$refs.currentComponent.resetPage();
                    }
                }
            }
        },
        search: {
            get: function() {
                return this.$store.getters['settings/getSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('settings/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('actionsource', ['loadDataSource']),
        async onToolbarClick(event, button) {
            switch (button?.Action) {
                case "New":
                {
                    switch (button?.Type) {
                        case 'Orders.Counter': {
                            try {
                                let counter = await this.$store.dispatch('dialogs/orderCounter/open', { id: null });

                                this.setOverlayVisible({ visible: true, message: 'Сохранение...' });

                                let saveResponse = await httpAPI({
                                    url: `api/actions/save?type=Orders.Counter`,
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    data: { Content : JSON.stringify({ parent: counter, childrens: null }) },
                                });

                                this.setOverlayVisible({ visible: false });

                                if (saveResponse) {
                                    await this.$refs.currentComponent.getData();
                                    this.$notify.success(saveResponse.data.message);
                                }

                            }
                            catch (ex) {
                                console.log(ex);
                            }
                        }
                        break;

                        case 'Documents.ExecuterGroup': {
                            try {
                                let exGroup = await this.$store.dispatch('dialogs/executersGroup/open', { id: null });

                                this.setOverlayVisible({ visible: true, message: 'Сохранение...' });

                                let saveResponse = await httpAPI({
                                    url: `api/actions/save?type=Documents.ExecuterGroup`,
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' },
                                    data: { Content : JSON.stringify({ parent: exGroup, childrens: null }) },
                                });

                                this.setOverlayVisible({ visible: false });

                                if (saveResponse) {
                                    await this.$refs.currentComponent.getData();
                                    this.$notify.success(saveResponse.data.message);
                                }

                            }
                            catch (ex) {
                                console.log(ex);
                            }
                        }
                        break;

                        default:
                            await this.loadDataSource({ createType: button.Type, navigate: true });
                            break;
                    }
                    break;
                }

                default:
                    break;
            }
        },
        async loadData() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/actions/collection?type=${this.collection}`,
                method: 'GET'
            });

            if (response) {

                let filterResponse = await httpAPI({
                    url: `/api/settings/filter?collection=${this.collection}`,
                    method: 'GET',
                    cancelToken: this.cancellationTokenSorce.token,
                });

                if (filterResponse) {
                    var permissions = (this.$store.getters['auth/getUserInfo'])?.permissions ?? [];
                    filterResponse.data.payload.filter.items = filterResponse.data.payload.filter.items.filter(i => this.$_.difference(i.requires, permissions).length === 0);
                    this.filterDataSource = filterResponse.data.payload;
                }

                this.dataSource = response.data.payload;
            }
        }
    },
    beforeCreate() {
        this.$store.dispatch('settings/initCollection')
    },
    async created() {
        this.setOverlayVisible({ visible: true });
        await this.loadData();
        this.setOverlayVisible({ visible: false });
    },
    watch: {
        collection() {
            this.loadData();
        }
    },
}
</script>